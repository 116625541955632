import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { API_URL, Loader } from "../App";
import { useNavigate } from "react-router-dom";
import IsLogin from "../components/IsLogin";
import Header from "../components/Header";

export function Bookings() {
    const [bookings, setBookings] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        fetch(API_URL + `/user-functions.php?method=fetch_bookings&uid=${localStorage.getItem("userUID")}`, {
            method: 'GET'
        })
            .then(res => res.json())
            .then(data => {
                setIsLoading(false);
                setBookings(data.bookings);
            })
            .catch(err => {
                console.log(err);
                setIsLoading(false);
            });
    }, []);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredBookings = bookings.filter((booking) => {
        const patientDetails = JSON.parse(booking.patient_details || "{}");
        return patientDetails.phone && patientDetails.phone.includes(searchTerm);
    });

    return (
        <div>
            <Header/>
            <IsLogin />
            <Loader loading={isLoading} />
            <div className="container" style={{marginBottom: "50px"}}>
                <div className="row mt-3">
                    <div className="col-md-12">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search by patient mobile"
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                    </div>
                </div>
                {filteredBookings.map((booking) => {
                    const patientDetails = JSON.parse(booking.patient_details || "{}");
                    return (
                        <div className="card p-3 mt-3" key={booking.id}>
                            <p className="fs-5">You have booked {(JSON).parse(booking.cart).length} tests
                                on {booking.date} at {booking.time}.</p>
                            <p className="fs-6 bold text-decoration-underline m-0">Patient Details</p>
                            <p className="fs-6 m-0">Name - {patientDetails.name || "N/A"}</p>
                            <p className="fs-6 m-0">Mobile - {patientDetails.phone || "N/A"}</p>
                            <p className="fs-6 m-0">Age - {patientDetails.age || "N/A"}</p>
                            <p className="fs-6 m-0">Gender - {patientDetails.gender || "N/A"}</p>
                            <p className="fs-6">Referring Doctor - {patientDetails.referringDoctor || "N/A"}</p>
                            <button className="btn btn-outline-primary mb-2">{booking.status.toUpperCase()}</button>
                            <button className="btn btn-danger mb-2" onClick={() => {
                                window.location.href = `${booking.prescription}`
                            }}>Attached Prescription
                            </button>
                            <button className="btn btn-primary" onClick={() => {
                                navigate(`/viewbooking/${booking.id}`)
                            }}>View Booking Info
                            </button>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
