// src/MapComponent.js
import React, { useState, useCallback, useEffect } from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

const containerStyle = {
    width: '100%',
    height: '400px'
};

const MapComponent = ({ onAddressChange, onLatChange, onLngChange }) => {
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyBSon1qM9sp3EyAzfrnDYP51n8LZ8JCQO4'
    });

    const [markerPosition, setMarkerPosition] = useState({ lat: 0, lng: 0 });
    const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const { latitude, longitude } = position.coords;
                setMarkerPosition({ lat: latitude, lng: longitude });
                setMapCenter({ lat: latitude, lng: longitude });

                onLatChange(latitude);
                onLngChange(longitude);

                const geocoder = new window.google.maps.Geocoder();
                geocoder.geocode({ location: { lat: latitude, lng: longitude } }, (results, status) => {
                    if (status === 'OK' && results[0]) {
                        onAddressChange(results[0].formatted_address);
                    } else {
                        console.error('Geocoder failed due to: ' + status);
                    }
                });

            });
        } else {
            // Default center if geolocation is not available
            setMapCenter({ lat: -3.745, lng: -38.523 });
            setMarkerPosition({ lat: -3.745, lng: -38.523 });
        }
    }, []);

    const onMarkerDragEnd = useCallback(async (event) => {
        const newLat = event.latLng.lat();
        const newLng = event.latLng.lng();
        setMarkerPosition({ lat: newLat, lng: newLng });
        onLatChange(newLat);
        onLngChange(newLng);
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: { lat: newLat, lng: newLng } }, (results, status) => {
            if (status === 'OK' && results[0]) {
                onAddressChange(results[0].formatted_address);
            } else {
                console.error('Geocoder failed due to: ' + status);
            }
        });
    }, [onAddressChange]);

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={mapCenter}
            zoom={10}
        >
            <Marker
                position={markerPosition}
                draggable={true}
                onDragEnd={onMarkerDragEnd}
            />
        </GoogleMap>
    ) : <></>;
};

export default MapComponent;
