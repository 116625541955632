import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import {useNavigate} from "react-router-dom";

export default function Header() {
    const navigate = useNavigate();
    return (
        <nav className="navbar d-flex flex-row card navbar-light bg-light px-4 mb-3">
            <a className="navbar-brand" style={{fontSize: 25, fontWeight: 500}} href="#">Jagruthi Lab</a>
            <div className="gap-4 d-none d-sm-block">
                <a onClick={() => {navigate('/home')}} className="navbar-brand" href="#">Home</a>
                <a onClick={() => {navigate('/bookings')}} className="navbar-brand" href="#">My Bookings</a>
                <a onClick={() => {navigate('/checkout')}} className="navbar-brand" href="#">Cart</a>
                <a onClick={() => {window.location.href = "tel:+919849183665"}} className="navbar-brand" href="#">Support</a>
            </div>
        </nav>
    )
}
