import React, {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { useNavigate } from 'react-router-dom';
import Header from "./Header";
import {API_URL, Loader} from "../App";

export default function Categories() {
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    function move(id) {
        navigate(`/category/${id}`)
    }

    useEffect(() => {
        setIsLoading(true)
        fetch(API_URL + "/user-functions.php?method=fetch_categories" ,{
            method: "GET",
        })
            .then(function(res) { return res.json() })
            .then(function(data) {
                if(data.status == true) {
                 setCategories(data.categories);
                } else {
                    setCategories([]);
                }
                setIsLoading(false)
            })
    }, []);

    return (
        <div>
            <Header/>
            <Loader loading={isLoading}/>
            <div className="container">
            <h5 className="text-center mb-3">Choose Category</h5>
                {categories.length > 0 ? (
                <div className="d-flex flex-wrap" style={{justifyItems: 'space-between', gap: '10px'}}>
                    {categories.map((category) => (
                        <div onClick={() => move(category.id)} key={category.id}
                             className="d-flex gap-2 card mb-1 rounded-5 p-3 justify-content-center align-items-center"
                             style={{width: "48.5%"}}>
                            <img src={category.image} alt="image"/>
                            <p>{category.name}</p>
                        </div>
                    ))}
                </div>
                    ) : (
                    <p className="text-center mt-3">No categories found</p>
                )}
            </div>
        </div>
    )
}
