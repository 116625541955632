import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';
import BiotechRoundedIcon from '@mui/icons-material/BiotechRounded';
import SupportRoundedIcon from '@mui/icons-material/SupportRounded';
import {useNavigate} from "react-router-dom";
import Swal from "sweetalert2";


export default function SimpleBottomNavigation() {
    const [value, setValue] = React.useState(0);
    const cart = localStorage.getItem('cart');
    const navigate = useNavigate();

    const showAlert = (icon, text) => {
        Swal.fire({
            text: text,
            icon: icon,
            timer: 2000, // 2 seconds
            timerProgressBar: true,
            showConfirmButton: false,
            showCancelButton: false
        }).then((result) => {
            // This code will run when the timer expires
            if (result.dismiss === Swal.DismissReason.timer) {
            }
        });

    };

    return (
        <div className="d-block d-lg-none position-fixed bottom-0" style={{left:0, right:0}}>
            <Box >
                <BottomNavigation
                    showLabels
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                >
                    <BottomNavigationAction label="Home" icon={<HomeRoundedIcon />} />
                    <BottomNavigationAction
                        onClick={() => {
                            navigate('/bookings')
                        }}
                        label="Bookings" icon={<BiotechRoundedIcon />} />
                    <BottomNavigationAction
                        onClick={() => {
                            if (cart && cart.length > 2) {
                                navigate('/checkout');
                            } else {
                                showAlert('warning', 'No tests currently in cart. Add tests before you could checkout.');
                                setValue(0)
                            }
                        }}
                        label="Cart" icon={<ShoppingCartRoundedIcon />} />
                    <BottomNavigationAction
                        onClick={() => {
                            window.location.href = "tel:+919849183665";
                            setValue(0)
                        }}
                        label="Support" icon={<SupportRoundedIcon />} />

                </BottomNavigation>
            </Box>
        </div>
    );
}
