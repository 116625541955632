import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import '../index.css';
import { API_URL, Loader } from "../App";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import IsLogin from "./isLogin";

export function EmployeeDashboard() {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [bookings, setBookings] = useState([]);
    const [filter, setFilter] = useState("pending");
    const [status, setStatus] = useState("");

    useEffect(() => {
        setIsLoading(true);
        fetch(API_URL + `/worker-functions.php?method=fetch_worker_status&uid=${localStorage.getItem("workerUID")}`, {
            method: 'GET',
        })
            .then(function(res) {return res.json()})
            .then(data => {
                setStatus(data.current)
                setIsLoading(false)
            })
            .catch(err => setIsLoading(false));
    }, [])

    function update_Status(status) {
        setIsLoading(true);
        fetch(API_URL + `/worker-functions.php?method=update_status&uid=${localStorage.getItem("workerUID")}&status=${status}`, {
            method: 'GET',
        })
            .then(res => setIsLoading(false))
            .catch(err => setIsLoading(false));
        setStatus(status)
    }

    useEffect(() => {
        setIsLoading(true);
        fetch(API_URL + `/worker-functions.php?method=fetch_bookings&filter=${filter}&uid=${localStorage.getItem("workerUID")}`, {
            method: "GET"
        })
            .then(res => res.json())
            .then(data => {
                if (data.status === true) {
                    setBookings(data.bookings);
                }
                setIsLoading(false);
            })
            .catch(err => {
                console.error(err);
                setIsLoading(false);
            });
    }, [filter]);

    const filteredBookings = bookings.filter(booking => booking.status === filter);

    return (
        <div>
            <IsLogin />
            <Loader loading={isLoading} />
            <nav className="navbar d-flex flex-row card navbar-light bg-light px-4 mb-3">
                <a className="navbar-brand" style={{ fontSize: 25, fontWeight: 500 }} href="#">Jagruthi Lab</a>
                <select value={status} className="p-2 bg-white border rounded-2" onChange={(e) => {update_Status(e.target.value)}}>
                    <option value="online">Online</option>
                    <option value="offline">Offline</option>
                </select>
            </nav>
            <div className="container flex-wrap" style={{marginBottom: "50px"}}>
                {/*<div className="card p-3 mt-3 d-flex w-100">*/}
                {/*    <p className="fs-6 m-0">Assigned Bookings</p>*/}
                {/*    <p className="fs-4 m-0">{bookings.length}</p>*/}
                {/*</div>*/}
                {/*<div className="card p-3 mt-3 d-flex w-100">*/}
                {/*    <p className="fs-6 m-0">Login Time (Today)</p>*/}
                {/*    <p className="fs-4 m-0">0</p>*/}
                {/*</div>*/}
                <p className="fs-4 text-center mt-3">Bookings</p>
                <select className="p-2 bg-white border rounded-2 shadow-none w-100" onChange={(e) => setFilter(e.target.value)}>
                    <option value="pending">Awaiting Pickup</option>
                    <option value="tests_picked_up_from_patient">Awaiting Submission to Lab</option>
                    <option value="submitted_to_lab">Marked as Completed</option>
                </select>
                <div>
                    {filteredBookings.length > 0 ? filteredBookings.map((item) => (
                        <div className="card p-3 mt-3" key={item.id}>
                            <div className="position-absolute"
                                 onClick={() => {
                                     window.location.href = `tel:${JSON.parse(item.patient_details).phone}`;
                                 }}
                                 style={{right: 20}}>
                                <i className="bi bi-telephone-fill btn btn-outline-primary"></i>
                            </div>
                            <p className="fs-6 bold text-decoration-underline m-0">Booking Details</p>
                            <p className="fs-6 m-0">Patient Name - {(() => {
                                try {
                                    return JSON.parse(item.patient_details).name;
                                } catch (e) {
                                    console.error("Failed to parse patient details:", e);
                                    return "N/A";
                                }
                            })()}</p>
                            <p className="fs-6 m-0 mb-3">Patient Mobile - {JSON.parse(item.patient_details).phone}</p>
                            <p className="fs-6 bold text-decoration-underline m-0">Slot Details</p>
                            <p className="fs-6 m-0">Pickup Date - {new Date(item.date).toLocaleDateString('en-US', {
                                day: 'numeric',
                                month: 'long',
                                year: 'numeric'
                            })}</p>
                            <p className="fs-6 m-0 mb-3">
                                Pickup Time - {(() => {
                                const [hours, minutes] = item.time.split(':');
                                let period = 'AM';
                                let hours12 = parseInt(hours, 10);

                                if (hours12 >= 12) {
                                    period = 'PM';
                                    if (hours12 > 12) hours12 -= 12;
                                }
                                if (hours12 === 0) hours12 = 12; // Handle midnight (0 hours)

                                return `${hours12}:${minutes} ${period}`;
                            })()}
                            </p>
                            <button className="btn btn-outline-primary mb-2"
                                    onClick={() => navigate(`/worker/booking/${item.id}`)}
                            >
                                View Booking
                            </button>
                            <button className="btn btn-primary mb-2" onClick={() => {
                                const googleMapsURL = `https://www.google.com/maps?q=${item.lat},${item.lng}`;
                                window.open(googleMapsURL, '_blank');
                            }}>Pickup Location
                            </button>
                        </div>
                    )) : (
                        <p className="text-center mt-5"> No Bookings Found </p>
                    )}
                </div>
            </div>
        </div>
    );
}
