import React, { CSSProperties, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { FadeLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import Illustration from "../assets/splash.png";
import IsLogin from "../components/IsLogin";

const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
};


export function Splash() {
    const [color, setColor] = useState("#0d6efd");

    return (
        <div className="container d-flex flex-column vh-100 justify-content-center align-items-center">
            <p className="fs-1 text-center m-0 fw-bolder">Welcome to Jagruthi Lab</p>
            <p className="fs-5 text-center">Get tested at your home and receive your lab reports directly on app.</p>
            <div className="mb-5"></div>
            <FadeLoader
                color={color}
                loading={true}
                cssOverride={override}
                size={50}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
            <IsLogin />
        </div>
    );
}
