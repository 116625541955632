import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Categories from '../components/Categories';
import SimpleBottomNavigation from "../components/BottomNavigation";
import TestItems from "../components/TestItems";
import Header from "../components/Header";
import IsLogin from "../components/IsLogin";

export default function Home() {
    return(
        <div>
            <IsLogin />
            <Categories/>
            <br/>
            <br/>
            <br/>
            <SimpleBottomNavigation />
        </div>
    )
}
