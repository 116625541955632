import React, { useEffect, useState, CSSProperties } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import {API_URL, Loader} from "../App";
import {useNavigate, useParams} from "react-router-dom";
import {BookingsWbooking} from "../pages/Bookings";
import IsLogin from "./isLogin";
import Swal from "sweetalert2";

export function Wbooking() {
    const [bookings, setBookings] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [status, setStatus] = useState("");
    const {id} = useParams();

    function updateStatus(status) {
        setIsLoading(true);
        fetch(`${API_URL}/worker-functions.php?method=update_booking_status&id=${id}&status=${status}&uid=${localStorage.getItem("workerUID")}`, {
            method: "GET",
        })
            .then(res => res.json())
            .then(data => {
                if (data.status === true) {
                    setStatus(data.current);
                } else {
                    Swal.fire('Error', 'Failed to update status', 'error');
                }
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error updating status:', error);
                Swal.fire('Error', 'Failed to update status', 'error');
                setIsLoading(false);
            });
    }

    const showUpdateOptions = () => {
        Swal.fire({
            title: 'Select Status to Update',
            input: 'select',
            inputOptions: {
                '1': 'Tests Picked Up from Customer',
                '2': 'Submitted to Lab',
            },
            inputPlaceholder: 'Select a status option',
            showCancelButton: true,
            inputValidator: value => {
                return new Promise(resolve => {
                    if (value !== '') {
                        resolve();
                    } else {
                        resolve('You need to select a status to update');
                    }
                });
            }
        }).then(result => {
            if (result.isConfirmed) {
                if (result.value == 1) {
                    setStatus('tests_picked_up_from_patient');
                    updateStatus("tests_picked_up_from_patient");
                } else if (result.value == 2) {
                    setStatus('submitted_to_lab');
                    updateStatus("submitted_to_lab");
                }
            }
        });
    };

    return(
        <div>
            <IsLogin/>
            <Loader/>
            <nav className="navbar d-flex flex-row card navbar-light bg-light px-4 mb-3">
                <a className="navbar-brand" style={{fontSize: 25, fontWeight: 500}} href="#">Jagruthi Lab</a>
            </nav>
            <div className="container mb-5" >
                <div className="card p-3 mb-3">
                    <button
                        onClick={showUpdateOptions}
                        className="btn btn-primary">Update Status</button>
                </div>
                <div className="mb-3">
                    <p className="fs-5 text-center">Booked Tests</p>
                    <Tests/>
                </div>
            </div>
        </div>
    )
}

function Tests() {
    const navigate = useNavigate();
    const [tests, setTests] = useState([]);
    const {id} = useParams();

    useEffect(() => {
        fetch(API_URL + `/worker-functions.php?method=fetch_specific_booking&id=${id}&uid=${localStorage.getItem("workerUID")}`, {
            method: "GET",
        })
            .then(res => res.json())
            .then(data => {
                if (data.status === true) {
                    setTests(data.tests);
                } else {
                    setTests([]);
                }
                console.log(tests);
            })
            .catch(error => console.error('Error fetching tests:', error));
    }, []); // Include cart in the dependency array

    return (
        <div>
            {/*<div id="view-cart-button" className="card p-3 mb-3">*/}
            {/*    <button className="btn btn-primary w-100" onClick={() => {*/}
            {/*        document.getElementById("view-cart-button").hidden = true;*/}
            {/*        document.getElementById("all-cart-items").hidden = true;*/}
            {/*    }}>View Cart</button>*/}
            {/*</div>*/}
            <div id="all-cart-items" className="d-flex flex-wrap gap-3 card p-3">
                {tests.length > 0 ? (
                    tests.map(test => (
                        <div key={test.id} className="card p-4 rounded-1 w-100" style={{ width: '300px' }}>
                            <h6 className="mb-1 fs-5">{test.name} (₹{test.price})</h6>
                            <p className="text-muted mb-2">{test.description}</p>
                        </div>
                    ))
                ) : (
                    <p className="text-muted mb-2 text-center">No tests found</p>
                )}
            </div>
        </div>
    );
}

